<template>
  <div class="reminder-box">
    <div class="ins_container" v-if="showInst">
      <p>
        {{ $t('deposit.default.depositInstruction') }}
      </p>
      <ul>
        <li v-for="(item, index) in instData" :key="index">
          {{ item }}
        </li>
      </ul>
      <slot name="instruction"></slot>
    </div>
    <div class="note_container" v-if="showNote">
      <p>
        {{ $t('deposit.default.depositNote') }}
      </p>
      <ul>
        <li v-for="(item, index) in noteData" :key="index">
          {{ item }}
        </li>
      </ul>
      <slot name="note"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DepositInfo',
  props: {
    showInst: {
      type: Boolean,
      default: true
    },
    showNote: {
      type: Boolean,
      default: true
    },
    instData: {
      type: Array,
      default: () => []
    },
    noteData: {
      type: Array,
      default: () => []
    }
  }
};
</script>
<style lang="scss" scoped>
.reminder-box {
  border: 1px solid $primary;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 40px;
  width: 95%;
  .ins_container {
    margin-bottom: 1em;
  }
  ul {
    li {
      list-style-type: decimal;
      @include rtl-sass-prop(margin-left, margin-right, 1em);
      margin-bottom: 5px;
      font-size: 14px;
      color: $dark-gray;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
