<template>
  <DefaultTemplate :showStep="formFlag">
    <div class="depositFunds-box">
      <div class="form-box" v-if="formFlag">
        <el-form label-position="top" :model="nigeriaForm" ref="nigeriaForm" status-icon :rules="nigerialRules">
          <div class="box_top">
            <div class="box_top_left box">
              <p>{{ $t('common.withdrawChannel.nigeriabanktransfer') }}</p>
              <DepositInfo :instData="instData" :showNote="showNote"></DepositInfo>
              <strong>{{ $t('deposit.default.deposit') }}</strong>
              <div class="info">
                <p>
                  {{ $t('deposit.intSwift.desc', { platform: $config.info.fullName }) }}
                </p>
                <p>{{ $t('deposit.intSwift.options') }}</p>
              </div>
              <AccountNumber
                :supportedCurrenciesList="validCurrencies"
                @setCurrency="setCurrency"
                @setAccountNumber="setAccountNumber"
              ></AccountNumber>
              <!-- BANK DETAILS -->
              <div class="bank_info">
                <ul v-for="item in bankInfos" :key="item.title">
                  <li v-if="item.info && item.info.show">
                    <span class="title_name">{{ $t(item.title) }}</span>
                    <span class="value_info">{{ item.info.value + ' ' + $t(item.desc) }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="box_top_right">
              <div class="logo circle bankNga"></div>
            </div>
          </div>
          <div class="box_bottom box">
            <div class="warn_info">
              <p>
                {{ $t('deposit.intSwift.refInfo', { platform: $config.info.fullName }) }}
              </p>
            </div>
            <strong>{{ $t('deposit.default.sendReceipt') }}</strong>
            <div class="info">
              <p>{{ $t('deposit.indiaBankTransfer.desc1') }}</p>
            </div>
            <div class="form-list">
              <ul class="clearfix">
                <li>
                  <el-form-item :label="$t('common.field.accNum')" class="special_input">
                    <div class="special_input_demo">{{ nigeriaForm.accountNumber }}</div>
                  </el-form-item>
                </li>
                <li>
                  <el-form-item :label="setAmtLabel(nigeriaForm.currency, nigeriaForm.amount)" prop="amount">
                    <numeric-input
                      v-model="nigeriaForm.amount"
                      :currency="nigeriaForm.currency"
                      :precision="2"
                    ></numeric-input>
                  </el-form-item>
                </li>
              </ul>
              <ul class="clearfix">
                <li>
                  <el-form-item :label="$t('common.field.notes')">
                    <el-input v-model="nigeriaForm.notes" data-testid="notes"></el-input>
                  </el-form-item>
                </li>
                <li class="data">
                  <p>
                    {{ $t('deposit.default.rate.rate', { oldCurrency: accountCurrency, newCurrency: 'NGN' }) }}
                    <span>{{ rate }}</span>
                  </p>
                  <p class="exchange">
                    NGN: <span>{{ rateChange }}</span>
                  </p>
                </li>
              </ul>
              <ul class="clearfix">
                <li>
                  <el-form-item :label="$t('common.field.upload')" prop="uploadFile">
                    <vUpload :limit="6" v-on:updateFileInfo="updateFileInfo" data-testid="updateFileInfo"></vUpload>
                  </el-form-item>
                </li>
              </ul>
              <ul class="clearfix">
                <li>
                  <el-form-item class="button">
                    <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
                      {{ $t('common.button.submit') }}
                    </el-button>
                  </el-form-item>
                </li>
              </ul>
            </div>
          </div>
        </el-form>
      </div>
      <Result v-if="successFlag">{{ $t('deposit.default.successMsg') }}</Result>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import vUpload from '@/components/vUpload';
import Result from '@/components/Result';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import mixin from '@/mixins/page/deposit';
import NigeriaSA from '@/bankInfo/NigeriaSA';
import rounding from '@/util/rounding';
import { apiQueryRate_GBP_NGN, apiQueryRate_USD_NGN, apiInternational_bank_payment } from '@/resource';

export default {
  name: 'nigeriaBankTransfer',
  components: { vUpload, NumericInput, Result, DefaultTemplate, AccountNumber, DepositInfo },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimit),
              currency: this.accountCurrency
            })
          )
        );
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.maxLimitByUSC) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' }))
        );
      } else {
        callback();
      }
    };

    const checkUploadFile = (rule, value, callback) => {
      if (!(this.fileList.length > 0)) {
        callback(new Error(this.$t('common.formValidation.fileReceipt')));
      }
      callback();
    };
    return {
      nigeriaForm: {
        accountNumber: '',
        amount: '',
        notes: ''
      },
      nigerialRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        uploadFile: [
          {
            validator: checkUploadFile,
            required: true,
            trigger: 'change'
          }
        ]
      },
      fileList: [],
      isShow: false,
      formFlag: true,
      successFlag: false,
      rate: 0.0,
      validCurrencies: ['USD', 'USC'],
      instData: [this.$t('deposit.reminder.inst14'), this.$t('deposit.reminder.inst16')],
      showNote: false
    };
  },
  computed: {
    bankInfos() {
      return NigeriaSA.bank_infos(this.accountCurrency);
    },
    rateChange() {
      return rounding(Math.ceil, this.rate * this.nigeriaForm.amount, 2);
    },
    bankInfoComponent() {
      return () => this.$config.componentLoader(this.regulator, 'deposit/InternationalBankInfo').component;
    }
  },
  watch: {
    accountCurrency: {
      immediate: true,
      handler(newValue) {
        if (newValue) this.queryCurrencyRate();
      }
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.nigeriaForm.accountNumber = accountNumber;
    },
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['nigeriaForm'].validateField('uploadFile');
    },
    submitForm() {
      this.$refs['nigeriaForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data) {
                this.formFlag = false;
                this.successFlag = true;
              } else {
                this.loading = false;
                this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiInternational_bank_payment(
        {
          mt4Account: this.nigeriaForm.accountNumber,
          operateAmount: this.nigeriaForm.amount,
          applicationNotes: this.nigeriaForm.notes,
          currency: this.accountCurrency,
          fileList: this.fileList
        },
        this.token
      );
    },
    queryCurrencyRate() {
      if (this.accountCurrency === 'USD' || this.accountCurrency === 'USC') {
        apiQueryRate_USD_NGN()
          .then(resp => {
            if (resp.data.code == 0) this.rate = resp.data.data;
            if (this.accountCurrency === 'USC') this.rate = this.getRate(this.rate);
          })
          .catch(err => {
            this.errorMessage(
              this.$t('deposit.default.rate.result.rateError', { oldCurrency: 'USD', newCurrency: 'NGN' })
            );
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/internationalAndAu.scss';
</style>
