<template>
  <div class="content-box">
    <div class="inner">
      <div class="default-box">
        <div class="main">
          <div class="max" :class="{ hideStep: !showStep }">
            <el-steps direction="vertical" v-if="showStep">
              <el-step icon="red"></el-step>
              <el-step icon="blue"></el-step>
              <el-step icon="red"></el-step>
            </el-steps>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultDeposit',
  props: { showStep: { type: Boolean, default: true } }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/template/deposit/defaultTemplate.scss';
</style>
